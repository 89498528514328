<template lang="pug">
  svg#iug_reload(width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M7.80995 0C5.38644 0 3.28955 1.47337 2.27312 3.60946L1.77634 2.13609C1.608 1.63665 1.08561 1.37348 0.609874 1.55116C0.132309 1.72885 -0.115623 2.27728 0.0527145 2.77673L1.29329 6.45918C1.46162 6.95959 1.98402 7.2218 2.45975 7.04315L5.96556 5.74171C6.44404 5.56498 6.69288 5.01559 6.52363 4.5171C6.3553 4.01574 5.8329 3.75353 5.35717 3.93122L3.92538 4.46332C4.64173 2.96306 6.10553 1.92191 7.80995 1.92191C10.2142 1.92191 12.1702 3.9754 12.1702 6.49952C12.1702 9.0246 10.2142 11.0791 7.80995 11.0791C7.80995 11.0791 6.81914 11.1146 6.81914 12.0559C6.81914 13 7.80995 13 7.80995 13C11.2224 13 14 10.085 14 6.49952C13.9991 2.916 11.2224 0 7.80995 0Z')
</template>

<script>
export default {
  name: 'IUG_ReloadSVG',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#667b87',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else {
        return this.color;
      }
    }
  },
};
</script>